import { RelationType, MsgType, EventType } from "matrix-js-sdk";

export async function sendMessage(message: string) {
  const { $chat } = useNuxtApp();
  const store = useChatStore();
  return $chat.client.sendEvent(store.currentRoom!.roomId, EventType.RoomMessage, {
    body: message,
    msgtype: MsgType.Text
  });
}

export async function sendThreadMessage(message: string) {
  const { $chat } = useNuxtApp();
  const store = useChatStore();
  const threadId = store.getThreadId;
  return $chat.client.sendEvent(
    store.currentRoom!.roomId,
    threadId! as string,
    EventType.RoomMessage, // Cast to the correct type
    {
      body: message,
      msgtype: MsgType.Text
    }
  );
}

export function updateMessage(commentId: string, content: string) {
  const { $chat } = useNuxtApp();
  const store = useChatStore();

  const eventContent = {
    body: "* " + content,
    msgtype: MsgType.Text,
    "m.new_content": {
      body: content,
      msgtype: MsgType.Text
    },
    "m.relates_to": {
      rel_type: RelationType.Replace,
      event_id: commentId
    }
  };

  return $chat.client.sendEvent(
    store.currentRoom!.roomId,
    EventType.RoomMessage,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventContent as any
  );
}

export async function deleteMessage(eventId: string) {
  const { $chat } = useNuxtApp();
  const store = useChatStore();
  try {
    return $chat.client.redactEvent(store.currentRoom!.roomId, eventId, undefined, {
      reason: CHAT_REDACTED_REASONS.DELETED_BY_USER
    });
  } catch (error) {
    console.error(`Failed to delete message with event ID ${eventId}`, error);
  }
}

export async function blockMessage(eventId: string) {
  const { $chat } = useNuxtApp();
  const store = useChatStore();
  try {
    return $chat.client.redactEvent(store.currentRoom!.roomId, eventId, undefined, {
      reason: CHAT_REDACTED_REASONS.BLOCKED_BY_MODERATOR
    });
  } catch (error) {
    console.error(`Failed to block message with event ID ${eventId}`, error);
  }
}
