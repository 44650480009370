import type { MatrixEvent } from "matrix-js-sdk";

export function handleRedactionEvent(event: MatrixEvent & { event: { redacts: string } }) {
  const store = useChatStore();
  const redactedEvent = store.getEvent(event.event.redacts);
  const { $i18n } = useNuxtApp();

  //event seems not to be in the event map yet
  if (!redactedEvent) return;

  if (redactedEvent.getType() === "m.room.message") {
    const comment = store.getComment(redactedEvent.getId()!);
    const reason = event.getContent()?.reason;
    if (comment) {
      comment.deleted = reason === CHAT_REDACTED_REASONS.DELETED_BY_USER;
      comment.blocked = reason === CHAT_REDACTED_REASONS.BLOCKED_BY_MODERATOR;
      comment.content = upgradePlainTextToEditorContent(event.getContent().reason);
      store.setComment(comment);
    } else {
      const createdAt = event.getDate()?.toISOString() ?? "";
      const newComment = {
        id: redactedEvent.getId()!,
        createdByUser: {
          id: redactedEvent.getSender(),
          username: extractUsernameFromAlias(redactedEvent.getSender()) ?? ""
        },
        createdAt,
        createdAtDay: formatDate({
          date: createdAt,
          relativeDays: true,
          reduced: true,
          yesterday: $i18n.t("datesAndTimes.yesterday"),
          today: $i18n.t("datesAndTimes.today"),
          locale: $i18n.locale
        }),
        content: upgradePlainTextToEditorContent(event.getContent().reason ?? ""),
        deleted: reason === CHAT_REDACTED_REASONS.DELETED_BY_USER,
        blocked: reason === CHAT_REDACTED_REASONS.BLOCKED_BY_MODERATOR,
        userReactions: [],
        threadAggregate: {
          count: 0
        }
      };
      store.setComment(newComment);
    }
    return;
  }

  if (redactedEvent.getType() === "m.reaction" && redactedEvent.getRelation()) {
    const comment = store.getComment(redactedEvent.getRelation()!.event_id!);
    if (comment) {
      comment.userReactions = comment?.userReactions?.filter(
        (reaction) => reaction.id !== redactedEvent.getId()
      );
      store.setComment(comment);
    }
  }
}
