import dayjs from "dayjs";
import { RelationType } from "matrix-js-sdk";
import type { MatrixEvent } from "matrix-js-sdk";

import type { Comment } from "~/types";

function checkPreviousUser(username: string, createdAt: string, previousComment?: Comment) {
  if (!previousComment) return false;
  if (dayjs(previousComment.createdAt).format("YYYY-MM-DD") !== dayjs(createdAt).format("YYYY-MM-DD")) {
    return false;
  }
  return previousComment.createdByUser.username === username;
}

function getThreadMessageCount(eventId: string, eventsMap: Map<string, MatrixEvent>) {
  const threadEvents = Array.from(eventsMap.values()).filter(
    (event) =>
      event.getRelation?.()?.event_id === eventId && event.getRelation?.()?.rel_type === RelationType.Thread
  );
  return threadEvents.length;
}

export function handleMessageEvent(event: MatrixEvent) {
  const store = useChatStore();
  if (!event.getContent().body) return;

  // comment update
  if (event.getRelation()?.event_id && event.getRelation()?.rel_type === RelationType.Replace) {
    const comment = store.getComment(event.getRelation()!.event_id!);
    if (!comment) return;
    comment.updatedAt = event.getDate()?.toISOString() ?? "";
    comment.content = upgradePlainTextToEditorContent(event.getContent()["m.new_content"].body);
    return;
  }

  // comment create
  const { body } = event.getContent();
  const createdAt = event.getDate()?.toISOString() ?? "";
  const username = extractUsernameFromAlias(event.getSender()) ?? "";
  const threadId = event.isRelation("m.thread") ? event.getRelation()?.event_id : undefined;
  const comments = store.getComments(!!threadId);
  const previousComment = comments.pop();
  const { $i18n } = useNuxtApp();

  store.setComment({
    id: event.getId()!,
    createdByUser: { username },
    createdAt,
    createdAtDay: formatDate({
      date: createdAt,
      relativeDays: true,
      reduced: true,
      yesterday: $i18n.t("datesAndTimes.yesterday"),
      today: $i18n.t("datesAndTimes.today"),
      locale: $i18n.locale.value
    }),
    content: upgradePlainTextToEditorContent(body || "deleted"),
    deleted: !body,
    blocked: false,
    hideUser: checkPreviousUser(username, createdAt, previousComment),
    userReactions: [],
    threadAggregate: {
      count: getThreadMessageCount(event.getId()!, store.eventsMap as Map<string, MatrixEvent>)
    },
    threadId
  });

  if (threadId) {
    // update thread aggregate count if allready loaded
    const threadRootComment = store.getComment(threadId);
    if (threadRootComment) {
      threadRootComment.threadAggregate.count = getThreadMessageCount(
        threadId,
        store.eventsMap as Map<string, MatrixEvent>
      );
      threadRootComment.threadAggregate.username = username;
      threadRootComment.threadAggregate.lastActivity = createdAt;
      store.setComment(threadRootComment);
    }
  }
}
