import type { FilterClaimsQuery, GetClaimByIdQuery } from "#gql";

type Claim = FilterClaimsQuery["data"][0];

export function getFirstOriginImage(origins: Claim["origins"]) {
  const file = getFirstDefinedField(origins, "file");
  return file
    ? {
        src: `/api/v1/files/${file.id}`,
        mimeType: file.mimeType
      }
    : undefined;
}

export function isSubmission(claimStatus: string) {
  return SUBMISSION_STATUSES.includes(claimStatus as ALL_CLAIM_STATUSES);
}
export function isAcceptedClaim(claimStatus: string) {
  return !isSubmission(claimStatus);
}

export function normalizeCategories(categories: NonNullable<GetClaimByIdQuery["claim"]>["claimCategories"]) {
  return categories.map((category) => ({
    name: category.categoryName,
    labelDe: category.category.labelDe,
    labelEn: category.category.labelEn
  }));
}
