import { EventType, type RelationType } from "matrix-js-sdk";

export async function reactToComment(emoji: string, commentId: string) {
  const { $chat } = useNuxtApp();
  const store = useChatStore();

  //check if reaction already exists, if so remove it first
  const comment = store.getComment(commentId);
  const reaction = comment?.userReactions?.find(
    (reaction) => reaction.emoji === emoji && reaction.user.username === $chat.client.getUserIdLocalpart()
  );

  if (reaction?.id) {
    console.log("Removing reaction", reaction.id);
    return store.removeReaction(reaction.id);
  }

  try {
    const event = {
      "m.relates_to": {
        rel_type: "m.annotation" as RelationType.Annotation, // Explicitly set to Annotation
        event_id: commentId,
        key: emoji
      }
    };
    return $chat.client.sendEvent(store.currentRoom!.roomId, EventType.Reaction, event);
  } catch (error) {
    console.error(`Failed to react to comment ${commentId}`, error);
  }
}

export async function removeReaction(reactionEventId: string) {
  const { $chat } = useNuxtApp();
  const store = useChatStore();
  try {
    return $chat.client.redactEvent(store.currentRoom!.roomId, reactionEventId);
  } catch (error) {
    console.error(`Failed to remove reaction with event ID ${reactionEventId}`, error);
  }
}
