export async function fetchNextPage() {
  const { $chat } = useNuxtApp();
  const store = useChatStore();
  if (!store.currentRoom) {
    console.warn("chat fetchNextPage: currentRoom not found");
    return;
  }

  store.isLoadingPage = true;
  try {
    await $chat.client.paginateEventTimeline(store.currentRoom!.getLiveTimeline(), {
      backwards: true,
      limit: store.eventPageSize
    });
  } catch (error) {
    //TODO: Error handling
    console.error("Failed to get next page", error);
  } finally {
    store.isLoadingPage = false;
  }
}
