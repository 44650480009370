// plugins/matrix.js
import { defineNuxtPlugin } from "#app";
import type { MatrixClient, MatrixEvent, Room } from "matrix-js-sdk";
import { ClientEvent, EventStatus, createClient, RoomEvent, RoomMemberEvent } from "matrix-js-sdk";
import { logger } from "matrix-js-sdk/lib/logger";

import type { Session } from "@ory/client";

logger.disableAll();

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();

  const client = ref(createClient({ baseUrl: config.public.chatBaseUrl }));

  const chatStore = useChatStore();

  const { $ory } = useNuxtApp();
  const { session } = $ory;

  watch(session, async (newSession: Session) => {
    if (newSession !== null) {
      chatStore.matrixIsReady = await chatStore.userLogin(client.value as MatrixClient);
    } else {
      console.debug("chat: User logged out of the matrix");
    }
  });

  // Listen for membership changes and accept invites to spaces

  client.value.on(RoomMemberEvent.Membership, (event, member) => {
    if (member.membership === "invite" && member.userId === client.value.getUserId()) {
      const roomId = member.roomId;
      const room = client.value.getRoom(roomId);
      const isSpace = room?.getType() === "m.space"; // Check if the room is a space

      if (isSpace) {
        // Automatically join the space
        client.value
          .joinRoom(roomId)
          .then(() => {
            console.debug(`Joined space: ${roomId}`);
          })
          .catch((error) => {
            console.error(`Failed to join space: ${roomId}`, error);
          });
      } else {
        // TODO: Notify user about the invite to a non-space room
        console.debug(`Invite to non-space room: ${roomId}`);
      }
    }
  });

  // local temp Id updating on event resolving
  client.value.on(ClientEvent.Room, (room) => {
    room.on(
      RoomEvent.LocalEchoUpdated,
      (event: MatrixEvent, room: Room, oldEventId?: string, _?: null | string) => {
        if (
          oldEventId &&
          event.event.event_id &&
          event.event.event_id !== oldEventId &&
          event.status === EventStatus.SENT
        ) {
          chatStore.updateEventId(event.event.event_id, oldEventId);
        }
      }
    );

    room.on(RoomEvent.Timeline, (event, room, toStartOfTimeline) => {
      if (toStartOfTimeline) return; // Ignore events from the start of the timeline
      if (room?.name !== chatStore.currentRoom?.name) return;
      chatStore.addEvent(event);
      chatStore.handleRoomEvent(event);
    });
  });
  // Listen for typing changes
  const { username } = useSessionInfo();

  client.value.on(RoomMemberEvent.Typing, (event, member) => {
    if (member.name === username.value) return;
    if (chatStore.currentRoom?.roomId !== member.roomId) return;
    if (member.typing) {
      chatStore.chats[chatStore.roomName].isTyping.push(member.name);
    } else {
      chatStore.chats[chatStore.roomName].isTyping = chatStore.chats[chatStore.roomName].isTyping.filter(
        (name) => name !== member.name
      );
    }
  });

  // exported Plugin functions

  return {
    provide: {
      chat: {
        client: client.value
      }
    }
  };
});
