export function extractUsernameFromAlias(alias: string | undefined | null) {
  if (!alias) return null;
  return alias.split(":")[0].slice(1);
}

export function upgradePlainTextToEditorContent(content: string) {
  try {
    const result = JSON.parse(content);
    if (typeof result !== "object") {
      console.error("chat upgradePlainTextToEditorContent found no an object", result);
      throw new Error("Invalid content");
    }
    return content;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return `{"type":"doc","content":[{"type":"paragraph","content":[${JSON.stringify({ type: "text", text: content })}]}]}`;
  }
}
