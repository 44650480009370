import { type SyncState, type MatrixClient, ClientEvent } from "matrix-js-sdk";

export async function acceptOpenInvitesToSpaces(matrixClient: MatrixClient) {
  const rooms = matrixClient.getRooms();
  const invites = rooms.filter((room) => {
    const membership = room.getMyMembership();
    const isSpace = room.getType() === "m.space"; // Check if the room is a space
    return membership === "invite" && isSpace;
  });

  invites.forEach(async (invite) => {
    // Automatically join the space
    await matrixClient
      .joinRoom(invite.roomId)
      .then(() => {
        console.log(`Joined space: ${invite.roomId}`);
      })
      .catch((error) => {
        console.error(`Failed to join space: ${invite.roomId}`, error);
      });
  });

  if (invites.length === 0) {
    console.log("No open invites to spaces.");
  }
}

async function getJWToken(apiBase: string): Promise<{ token: string }> {
  const jwtUrl = new URL("/api/v1/chat/token", apiBase);
  const response = await fetch(jwtUrl);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await response.json();
  return data;
}

export async function userLogin(client: MatrixClient) {
  let matrixIsReady = false;
  const store = useChatStore();
  const config = useRuntimeConfig();

  try {
    const { token } = await getJWToken(config.public.apiBase);
    await client.login("org.matrix.login.jwt", { token });
    client.startClient({ initialSyncLimit: store.eventPageSize });

    // Listen for the sync event
    await new Promise((resolve) => {
      client.once(ClientEvent.Sync, async (state: SyncState) => {
        if (state === "PREPARED") {
          matrixIsReady = true;
          await store.acceptOpenInvitesToSpaces(client);
          resolve(true);
        }
      });
    });
    return matrixIsReady;
  } catch (error) {
    console.error("chat: Login failed", error);
    console.log("matrixIsReady is false", matrixIsReady);
    return false;
  }
}
