import payload_plugin_qLmFnukI99 from "/src/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/src/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/src/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/src/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_BwfRt8ewMW from "/src/node_modules/vue-sonner/lib/nuxt/runtime/plugin.js";
import plugin_PSbK9A48ZQ from "/src/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_client_OVoKJro5pc from "/src/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_ucMK8VhJjR from "/src/node_modules/nuxt-easy-lightbox/dist/runtime/plugin.mjs";
import plugin_U4BUIcs89R from "/src/node_modules/nuxt-graphql-client/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_VrpP1JaYg8 from "/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_sq1MuCrqbC from "/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_Jozdw60ZsE from "/src/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import _00_autoanimate_7DOFhALmXC from "/src/src/plugins/00.autoanimate.ts";
import _00_logging_client_QlUNsdTDMz from "/src/src/plugins/00.logging.client.ts";
import _00_vue_query_DELZJzQDA5 from "/src/src/plugins/00.vue-query.ts";
import _01_ory_sdk_kdtbcCaecy from "/src/src/plugins/01.ory-sdk.ts";
import _02_chat_client_EaMBq8eMwg from "/src/src/plugins/02.chat.client.ts";
import _02_eventStream_client_sdf9Ovyhd7 from "/src/src/plugins/02.eventStream.client.ts";
import ssg_detect_3fHkBxLtv0 from "/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_BwfRt8ewMW,
  plugin_PSbK9A48ZQ,
  plugin_client_OVoKJro5pc,
  plugin_ucMK8VhJjR,
  plugin_U4BUIcs89R,
  switch_locale_path_ssr_5csfIgkrBP,
  route_locale_detect_VrpP1JaYg8,
  i18n_sq1MuCrqbC,
  plugin_Jozdw60ZsE,
  _00_autoanimate_7DOFhALmXC,
  _00_logging_client_QlUNsdTDMz,
  _00_vue_query_DELZJzQDA5,
  _01_ory_sdk_kdtbcCaecy,
  _02_chat_client_EaMBq8eMwg,
  _02_eventStream_client_sdf9Ovyhd7,
  ssg_detect_3fHkBxLtv0
]