export function addThread(commentId: string) {
  const store = useChatStore();
  if (store.chats[store.roomName]?.threadId === commentId) return;
  store.chats[store.roomName] = {
    ...store.chats[store.roomName],
    threadId: commentId,
    showThirdPanel: true
  };
  const userProfileStore = useUserProfileStore();
  userProfileStore.username = "";
  store.threadIsReady = true;
}

export function removeThread() {
  const store = useChatStore();
  if (!store.roomName || !store.chats[store.roomName]) return;
  store.chats[store.roomName] = {
    ...store.chats[store.roomName],
    threadId: undefined,
    showThirdPanel: false
  };
}

export function setChatScrollTop(isThread: boolean, scrollTop: number) {
  const store = useChatStore();
  const id = isThread ? store.getThreadId : store.roomName;
  if (!id) return;
  if (!store.chats[id]) {
    store.chats[id] = { scrollTop, threadId: undefined, showThirdPanel: false, isTyping: [] };
  } else {
    store.chats[id].scrollTop = scrollTop;
  }
}

export function setThirdPanel(showThirdPanel: boolean) {
  const store = useChatStore();
  if (!store.chats[store.roomName]) {
    store.chats[store.roomName] = { scrollTop: 0, showThirdPanel, threadId: undefined, isTyping: [] };
  } else {
    store.chats[store.roomName].showThirdPanel = showThirdPanel;
  }
}
