<script lang="ts" setup>
import type { ButtonVariants } from "~/components/Basic/Button/variants";

const props = defineProps<{
  message?: string;
  onConfirm: (event: MouseEvent) => void;
  onCancel: (event: MouseEvent) => void;
  size?: ButtonVariants["size"];
  variant?: ButtonVariants["variant"];
  class?: string;
  autofocus?: boolean;
  confirmLayout?: "row" | "column";
}>();
defineEmits<{
  (event: "confirm" | "cancel", e: MouseEvent): void;
}>();
</script>

<template>
  <ul
    :class="
      cn(
        'flex items-center gap-1',
        {
          'flex-col': confirmLayout === 'column'
        },
        props.class
      )
    "
  >
    <li>
      <BasicButton
        :autofocus="autofocus"
        :size="size || 'sm'"
        :icon="ICONS.confirm"
        @click.stop.prevent="(event: MouseEvent) => $emit('confirm', event)"
      />
    </li>
    <li>
      <BasicButton
        :size="size || 'sm'"
        :icon="ICONS.cancel"
        @click.prevent.stop="(event: MouseEvent) => $emit('cancel', event)"
      />
    </li>
    <p v-if="message" class="ml-2">{{ message }}</p>
  </ul>
</template>
