import type { MatrixEvent } from "matrix-js-sdk";

export function handleMemberEvent(event: MatrixEvent) {
  const store = useChatStore();

  const username = extractUsernameFromAlias(event.event.sender);
  if (!username) {
    console.error("chat: handleMemberEvent: username not found", event.event.sender);
    return;
  }

  store.participants.push({
    username
  });
}
