import type { MatrixEvent, Room } from "matrix-js-sdk";
import { defineStore } from "pinia";

import * as chats from "./actions/chats";
import * as events from "./actions/events";
import * as fetchNextPage from "./actions/fetchNextPage";
import * as handleMemberEvent from "./actions/handleMemberEvent";
import * as handleMessageEvent from "./actions/handleMessageEvent";
import * as handleReactionEvent from "./actions/handleReactionEvent";
import * as handleRedactionEvent from "./actions/handleRedactionEvent";
import * as handleRoomEvent from "./actions/handleRoomEvent";
import * as messages from "./actions/messages";
import * as reactions from "./actions/reactions";
import * as sendTyping from "./actions/sendTyping";
import * as switchToRoom from "./actions/switchToRoom";
import * as switchToThread from "./actions/switchToThread";
import * as userLogin from "./actions/userLogin";
import type { Comment, Participant } from "~/types";

export type ChatStoreState = {
  matrixIsReady: boolean;
  roomIsReady: boolean;
  threadIsReady: boolean;

  eventPageSize: number;
  roomName: string;

  isLoadingRoom: boolean;
  isLoadingPage: boolean;
  participants: Participant[];
  currentRoom: Room | null;
  roomAlias: string;
  error: string | null;

  sendIsTypingPending: boolean;

  commentsMap: Map<string, Comment>;
  eventsMap: Map<string, MatrixEvent>;

  chats: {
    [roomName: string]: {
      scrollTop: number;
      showThirdPanel: boolean;
      threadId: string | undefined;
      isTyping: string[];
    };
  };
};

export const useChatStore = defineStore("chat", {
  state: () =>
    ({
      matrixIsReady: false,
      roomIsReady: false,
      threadIsReady: false,

      eventPageSize: 50,
      roomName: "",

      isLoadingRoom: false,
      isLoadingPage: false,
      participants: [],
      currentRoom: null,
      roomAlias: "",
      error: null,

      sendIsTypingPending: false,
      commentsMap: new Map(),
      eventsMap: new Map<string, MatrixEvent>(),

      chats: {}
    }) as ChatStoreState,
  actions: {
    ...userLogin,

    ...switchToRoom,
    ...switchToThread,
    ...messages,
    ...reactions,
    ...fetchNextPage,

    ...events,

    ...handleMemberEvent,
    ...handleMessageEvent,
    ...handleReactionEvent,
    ...handleRedactionEvent,
    ...handleRoomEvent,

    ...sendTyping,

    ...chats
  },
  getters: {
    getThreadId: (state) => {
      return state.chats[state.roomName]?.threadId;
    },
    getChatScrollTop: (state) => (isThread: boolean) => {
      const id = isThread ? state.chats[state.roomName]?.threadId : state.roomName;
      if (!id) return 0;
      return state.chats[id]?.scrollTop;
    },

    // event getters
    getEvent: (state) => (eventId: string) => {
      return state.eventsMap.get(eventId);
    },
    getComment: (state) => (eventId: string) => {
      return state.commentsMap.get(eventId);
    },
    getComments: (state) => (isThread: boolean) => {
      const comments = Array.from(state.commentsMap.values());
      const threadId = state.chats[state.roomName]?.threadId;
      return isThread
        ? comments.filter((comment) => comment.threadId === threadId)
        : comments.filter((comment) => !comment.threadId);
    },
    getThreadRootComment: (state) => {
      const threadId = state.chats[state.roomName]?.threadId;
      return state.commentsMap.get(threadId!);
    },
    getLatestComment(state) {
      const comments = Array.from(state.commentsMap.values());
      return comments.length > 0 ? comments[comments.length - 1] : {};
    }
  }
});
