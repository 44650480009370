import { defineStore } from "pinia";

export const useUserProfileStore = defineStore("userProfile", {
  state: () => ({
    username: ""
  }),
  actions: {
    removeUsername() {
      this.username = "";
      const chatStore = useChatStore();
      if (!chatStore.chats[chatStore.roomName]?.threadId) {
        chatStore.setThirdPanel(false);
      }
    },
    setUser(username: string) {
      this.username = username;
      const chatStore = useChatStore();
      chatStore.setThirdPanel(true);
    }
  }
});
