import type { MatrixEvent } from "matrix-js-sdk";

export function handleRoomEvent(event: MatrixEvent) {
  const store = useChatStore();

  if (event.getType() === "m.room.message") {
    store.handleMessageEvent(event);
    return;
  }
  if (event.getType() === "m.reaction") {
    store.handleReactionEvent(event.event);
    return;
  }
  if (event.getType() === "m.room.redaction") {
    store.handleRedactionEvent(event as MatrixEvent & { event: { redacts: string } });
    return;
  }
  if (event.getType() === "m.room.member") {
    store.handleMemberEvent(event);
    return;
  }
}
