import type { IEvent } from "matrix-js-sdk";

export function handleReactionEvent(event: Partial<IEvent>) {
  const store = useChatStore();
  const commentId = event.content?.["m.relates_to"]?.event_id;
  const comment = store.getComment(commentId!);

  if (comment && event.event_id) {
    const createdAt = new Date().toISOString();
    const reaction = {
      id: event.event_id,
      emoji: event.content?.["m.relates_to"]?.key ?? "",
      createdAt,
      user: {
        username: extractUsernameFromAlias(event.sender) ?? ""
      }
    };
    comment.userReactions?.push({ ...reaction, pending: event.event_id.startsWith("~") });
    store.setComment(comment);
  }
}
