import dayjs from "dayjs";

type Props = {
  date?: string | null;
  yesterday: string;
  today: string;
  locale: string;
  time?: boolean;
  relative?: boolean;
  relativeDays?: boolean;
  reduced?: boolean;
};

function isRelative(date: string) {
  const now = dayjs();
  const dateObj = dayjs(date);
  return now.diff(dateObj, "days") <= 1;
}

export default function formatDate({
  yesterday,
  today,
  locale,
  date,
  time,
  relative,
  relativeDays,
  reduced
}: Props) {
  if (!date) return "";

  if (time) {
    return dayjs(date).format(
      locale === "de" ? `HH:mm ${!reduced ? "[Uhr]" : ""}` : `h:mm ${!reduced ? "A" : ""}`
    );
  }
  if (relativeDays) {
    const isToday = dayjs(date).isSame(dayjs(), "day");
    if (isToday) return today;
    const isYesterday = dayjs(date).isSame(dayjs().subtract(1, "day"), "day");
    if (isYesterday) return yesterday;
  }
  if (relative && isRelative(date)) return dayjs(date).locale(locale).fromNow();
  const year = dayjs(date).get("year") === dayjs().get("year") ? "" : locale === "de" ? " YYYY" : ", YYYY";
  const timeDe = reduced ? "" : ", HH:mm [Uhr]";
  const timeEn = reduced ? "" : ", h:mm A";
  return dayjs(date).format(locale === "de" ? `D. MMMM${year}${timeDe}` : `MMMM D${year}${timeEn}`);
}
